body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
del,
small,
strike,
strong,
sub,
sup,
tt,
var,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/** RESET END */
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif; }

span,
p,
a {
  font-family: "Roboto", sans-serif; }

h2 {
  font-size: 2em;
  margin: 1em; }

.raid-container li {
  border: 1px solid black;
  box-shadow: 1px 6px 2px #a0a0a0;
  display: -webkit-flex;
  display: flex;
  min-height: 120px;
  margin: 0 auto;
  margin-bottom: 45px;
  width: 90%;
  position: relative;
  padding-bottom: 20px; }
  .raid-container li > div {
    width: 50%; }
  .raid-container li a.gym-btn-more-info {
    position: absolute;
    bottom: 0;
    right: 10px;
    margin-bottom: -17px;
    width: 100px;
    height: 30px;
    background-color: steelblue;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 5px 7px 0px #a0a0a0;
    padding-top: 11px;
    color: white;
    text-decoration: none; }
  .raid-container li .raid-logo img {
    width: 50%;
    height: auto; }

.countdown-timer--txt {
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums; }

.body {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain; }

.App {
  text-align: center;
  max-width: 400px;
  /* prohibits autorefresh in pulling down refresh */ }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-title {
  font-size: 1.5em; }

.App-intro {
  font-size: large; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.navigation-wrapper {
  position: fixed;
  left: calc(50% - 25px);
  bottom: 100px; }
  .navigation-wrapper .navigation-button {
    position: absolute;
    z-index: 15;
    width: 55px;
    height: 55px;
    background-color: #26a69a;
    border: 3px solid #122f92;
    border-radius: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .navigation-wrapper .navigation-button > button {
      width: 40px;
      height: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      background-color: #395063;
      border: 1px solid black;
      border-radius: 50px;
      outline: 0;
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
      .navigation-wrapper .navigation-button > button span {
        font-size: 15px; }
  .navigation-wrapper .subnav__button {
    position: absolute;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    left: 0px;
    top: 0px; }
  .navigation-wrapper .navigation-button-raid {
    width: 50px;
    height: 50px;
    background-color: #395063;
    border-radius: 40px;
    z-index: 9;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    outline: 0;
    border: none; }
  .navigation-wrapper .navigation-button-settings {
    width: 50px;
    height: 50px;
    background-color: #395063;
    border-radius: 40px; }
  .navigation-wrapper.active .navigation-button {
    background-color: white; }
    .navigation-wrapper.active .navigation-button button {
      width: 40px;
      height: 40px; }
  .navigation-wrapper.active .navigation-button-raid {
    -webkit-transform: translateX(-50px) translateY(-45px);
            transform: translateX(-50px) translateY(-45px); }
  .navigation-wrapper.active .navigation-button-settings {
    -webkit-transform: translateX(50px) translateY(-45px);
            transform: translateX(50px) translateY(-45px); }

.gymcard {
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  margin: 20px; }
  .gymcard header {
    -webkit-align-items: center;
            align-items: center;
    color: #363636;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    font-weight: 700;
    padding: 0.75rem; }
    .gymcard header p {
      -webkit-align-items: center;
              align-items: center;
      color: #363636;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      font-weight: 700;
      padding: 0.75rem; }
  .gymcard footer {
    background-color: transparent;
    border-top: 1px solid #dbdbdb;
    -webkit-align-items: stretch;
            align-items: stretch;
    display: -webkit-flex;
    display: flex; }
    .gymcard footer a {
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-basis: 0;
              flex-basis: 0;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      -webkit-justify-content: center;
              justify-content: center;
      padding: 0.75rem; }

.gym-card__item .raid-proximity-timer-wrapper {
  text-align: left;
  margin: auto;
  width: 90px; }
  .gym-card__item .raid-proximity-timer-wrapper svg {
    margin-right: 5px; }

.gym-card__item .raid-info__wrapper {
  text-align: left; }
  .gym-card__item .raid-info__wrapper .raid-gym-name {
    margin-bottom: 10px; }

.steps__wrapper {
  min-height: 200px;
  box-sizing: border-box; }
  .steps__wrapper .button--actions {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    display: -webkit-flex;
    display: flex; }

ul.pokemon__list {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around; }
  ul.pokemon__list li {
    width: 110px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    ul.pokemon__list li.active {
      border: 1px solid blue; }

.button__wrapper {
  margin-top: 5px; }

.gym--triangle {
  width: 80px;
  height: 80px;
  position: relative;
  border: 1px solid black;
  margin: 35px auto;
  -webkit-transform: rotate(222deg);
          transform: rotate(222deg); }

.gym--boss {
  text-transform: capitalize; }

.chat-wrapper {
  margin-top: 15px; }
  .chat-wrapper .AddComment {
    margin-bottom: 15px; }
    .chat-wrapper .AddComment button {
      margin-top: 5px; }
  .chat-wrapper .gym--actions {
    display: -webkit-flex;
    display: flex;
    margin: 20px 0; }
  .chat-wrapper article {
    padding: 5px 10px;
    text-align: left; }
    .chat-wrapper article .comment--author {
      padding-right: 5px;
      font-weight: 600; }
    .chat-wrapper article .comment--date {
      padding-right: 10px;
      color: #969696; }

