ul.pokemon__list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  li {
    width: 110px;
    display: flex;
    flex-direction: column;
    &.active {
      border: 1px solid blue;
    }
  }
}
.button__wrapper {
  margin-top: 5px;
}
