$buttonSize: 55px;

.navigation-wrapper {
  position: fixed;
  left: calc(50% - 25px);
  bottom: 100px;
  .navigation-button {
    position: absolute;
    z-index: 15;
    width: $buttonSize;
    height: $buttonSize;
    background-color: #26a69a;
    border: 3px solid #122f92;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    > button {
      width: $buttonSize - 15px;
      height: $buttonSize - 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #395063;
      border: 1px solid black;
      border-radius: 50px;
      outline: 0;
      transition: all 0.5s;
      span {
        font-size: 15px;
      }
    }
  }
  .subnav__button {
    position: absolute;
    transition: all 0.5s;
    left: 0px;
    top: 0px;
  }
  .navigation-button-raid {
    width: $buttonSize - 5px;
    height: $buttonSize - 5px;
    background-color: #395063;
    border-radius: 40px;

    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: none;
  }
  .navigation-button-settings {
    width: $buttonSize - 5px;
    height: $buttonSize - 5px;
    background-color: #395063;
    border-radius: 40px;
  }
  &.active {
    .navigation-button {
      background-color: white;

      button {
        width: 40px;
        height: 40px;
      }
    }
    .navigation-button-raid {
      transform: translateX(-50px) translateY(-45px);
    }
    .navigation-button-settings {
      transform: translateX(50px) translateY(-45px);
    }
  }
}
