.gymcard {
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  margin: 20px;
  header {
    align-items: center;
    color: #363636;
    display: flex;
    flex-grow: 1;
    font-weight: 700;
    padding: 0.75rem;
    p {
      align-items: center;
      color: #363636;
      display: flex;
      flex-grow: 1;
      font-weight: 700;
      padding: 0.75rem;
    }
  }
  footer {
    background-color: transparent;
    border-top: 1px solid #dbdbdb;
    align-items: stretch;
    display: flex;
    a {
      align-items: center;
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: center;
      padding: 0.75rem;
    }
  }
}
.gym-card__item {
  .raid-proximity-timer-wrapper {
    text-align: left;
    margin: auto;
    width: 90px;
    svg {
      margin-right: 5px;
    }
  }
  .raid-info__wrapper {
    text-align: left;
    .raid-gym-name {
      margin-bottom: 10px;
    }
  }
}
.steps__wrapper {
  min-height: 200px;
  box-sizing: border-box;
  .button--actions {
    justify-content: space-evenly;
    display: flex;
  }
}
